

import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./reducers/user";
import { sellerReducer } from "./reducers/seller";
import { productReducer } from "./reducers/product";
import { eventReducer } from "./reducers/event";
import { cartReducer } from "./reducers/cart";
import { wishlistReducer } from "./reducers/wishlist";
import { orderReducer } from "./reducers/order";
import { userApi} from "./userApiSlice"; // Ensure this path is correct
import { shopApi } from "./shopApiSlice";
import { adminApi } from "./adminApiSlice";
import { productApi } from "./productApi";
import { eventApi } from "./EventApi";
import { paymentApi } from "./paymentApi";
import { profileApi } from "./profileApiSlice";
import { couponApi } from "./couponApi";
import { messagesApi } from "./messagesApi";
import authReducer from './authSlice';
import shopauthReducer from "./shopAuthSlice"
import { orderApi } from "./orderApi";
const Store = configureStore({
  reducer: {
    user: userReducer,
    seller: sellerReducer,
    products: productReducer,
    events: eventReducer,
    cart: cartReducer,
    wishlist: wishlistReducer,
    order: orderReducer,
    // Add apiSlice reducer here
    auth: authReducer,
    shopauth:shopauthReducer,

    [userApi.reducerPath]: userApi.reducer,
[shopApi.reducerPath]:shopApi.reducer,
[adminApi.reducerPath]:adminApi.reducer,
[productApi.reducerPath]:productApi.reducer,
[eventApi.reducerPath]:eventApi.reducer,
[paymentApi.reducerPath]:paymentApi.reducer,
[profileApi.reducerPath]:productApi.reducer,
[orderApi.reducerPath]:orderApi.reducer,

[couponApi.reducerPath]:couponApi.reducer,
[messagesApi.reducerPath]:messagesApi.reducer,
},
  // Adding middleware for RTK Query
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat( userApi.middleware,shopApi.middleware,adminApi.middleware ,productApi.middleware,eventApi.middleware,paymentApi.middleware,profileApi.middleware,
      couponApi.middleware,messagesApi.middleware,orderApi.middleware),
});

export default Store;







