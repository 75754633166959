
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define the base URL for the user API
const USERS_URL = 'https://api.viceindustries.in/api/v2';

export const paymentApi = createApi({
  reducerPath: 'paymentApi',
  baseQuery: fetchBaseQuery({
    baseUrl: USERS_URL,
    credentials: 'include', 
  }),
  endpoints: (builder) => ({
    createOrders: builder.mutation({
      query: (data) => ({
        url: '/order/create-order', 
        method: 'post',
        body: data,
      }),
    }),
    paymentProcess: builder.mutation({
      query: (data) => ({
        url: '/payment/process', 
        method: 'post',
        body: data,
      }),
    }),
    getRazorpayapikey: builder.query({

      query: () => "/payment/razorpayapikey",
    }),

    getStripeapikey: builder.query({

      query: () => "/payment/stripeapikey",
    }),
  }),
});


export const { useCreateOrdersMutation,useGetStripeapikeyQuery,usePaymentProcessMutation,useGetRazorpayapikeyQuery} = paymentApi;
// const { data } = await axios.get(`${server}/payment/stripeapikey`);


