import React from 'react'
import CheckoutSteps from '../components/Checkout/CheckoutSteps'
import Footer from '../components/Layout/Footer'
import Header from '../components/Layout/Header'
import OrderSuccess from "./OrderSuccessPage.jsx";
const SuccessPage = () => {
  return (
    <div className='w-full min-h-screen bg-[#f6f9fc]'>
    <Header />
    <br />
    <br />
    <CheckoutSteps active={3} />
    <OrderSuccess />
    <br />
    <br />
    <Footer />
</div>
  )
}

export default SuccessPage