import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import { BsPencil } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";
import styles from "../../styles/styles";
import { toast } from "react-toastify";
import { useGetAllWithdrawRequestQuery, useUpdateAllWithdrawRequestMutation } from "../../redux/adminApiSlice";

const AllWithdraw = () => {
  const { data, error, isLoading } = useGetAllWithdrawRequestQuery();
  const [updateAllWithdrawRequest] = useUpdateAllWithdrawRequestMutation();
  const [open, setOpen] = useState(false);
  const [withdrawData, setWithdrawData] = useState(null);
  const [withdrawStatus, setWithdrawStatus] = useState("Processing");

  const columns = [
    { field: "id", headerName: "Withdraw Id", minWidth: 150, flex: 0.7 },
    { field: "name", headerName: "Shop Name", minWidth: 180, flex: 1.4 },
    { field: "shopId", headerName: "Shop Id", minWidth: 180, flex: 1.4 },
    { field: "amount", headerName: "Amount", minWidth: 100, flex: 0.6 },
    { field: "status", headerName: "Status", minWidth: 80, flex: 0.5 },
    { field: "createdAt", headerName: "Request given at", minWidth: 130, flex: 0.6 },
    {
      field: "update",
      headerName: "Update Status",
      minWidth: 130,
      flex: 0.6,
      renderCell: (params) => (
        <BsPencil
          size={20}
          className={`${params.row.status !== "Processing" ? "hidden" : ""} mr-5 cursor-pointer`}
          onClick={() => {
            setWithdrawData(params.row);
            setOpen(true);
          }}
        />
      ),
    },
  ];

  const handleSubmit = async () => {
    try {
      await updateAllWithdrawRequest({
        id: withdrawData.id,
        status: withdrawStatus,
      }).unwrap();
      toast.success("Withdraw request updated successfully!");
      setOpen(false);
    } catch (err) {
      toast.error("Failed to update withdraw request.");
    }
  };

  const rows = data?.withdraws?.map(item => ({
    id: item._id,
    shopId: item.seller._id,
    name: item.seller.name,
    amount: `US$ ${item.amount}`,
    status: item.status,
    createdAt: item.createdAt.slice(0, 10),
  })) || [];

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading data!</div>;

  return (
    <div className="w-full flex items-center pt-5 justify-center">
      <div className="w-[95%] bg-white">
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          autoHeight
        />
      </div>
      {open && (
        <div className="w-full fixed h-screen top-0 left-0 bg-[#00000031] z-[9999] flex items-center justify-center">
          <div className="w-[50%] min-h-[40vh] bg-white rounded shadow p-4">
            <div className="flex justify-end w-full">
              <RxCross1 size={25} onClick={() => setOpen(false)} />
            </div>
            <h1 className="text-[25px] text-center font-Poppins">Update Withdraw status</h1>
            <br />
            <select
              value={withdrawStatus}
              onChange={(e) => setWithdrawStatus(e.target.value)}
              className="w-[200px] h-[35px] border rounded"
            >
              <option value="Processing">Processing</option>
              <option value="Succeed">Succeed</option>
              <option value="Failed">Failed</option>
            </select>
            <button
              type="submit"
              className={`block ${styles.button} text-white !h-[42px] mt-4 text-[18px]`}
              onClick={handleSubmit}
            >
              Update
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllWithdraw;
