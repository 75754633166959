import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define the base URL for the coupon API
const COUPONS_URL = 'https://api.viceindustries.in/api/v2/coupon';

// Create the coupon API slice
export const couponApi = createApi({
  reducerPath: 'couponApi', // Corrected naming
  baseQuery: fetchBaseQuery({
    baseUrl: COUPONS_URL,
    credentials: 'include',
  }),
  endpoints: (builder) => ({
    getCouponValue: builder.query({
      query: () => '/get-coupon-value',
    }),
    createCouponCode: builder.mutation({
      query: (data) => ({
        url: '/create-coupon-code', 
        method: 'post',
        body: data,
      }),
    }),
    deleteCoupon: builder.mutation({
      query: (data) => ({
        url: '/delete-coupon', 
        method: 'delete',
        body: data,
      }),
    }),
    getCoupons: builder.query({
      query:(id)=> `/get-coupon/${id}`,
    }),
  }),
});

// Export the hook for the query
export const { useGetCouponValueQuery ,useGetCouponsQuery,useCreateCouponCodeMutation,useDeleteCouponMutation} = couponApi;
