
// import React, { useState, useEffect } from "react";
// import "./App.css";
// import Store from "./redux/store";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import {
//   LoginPage,
//   SignupPage,
//   ActivationPage,
//   HomePage,
//   ProductsPage,
//   BestSellingPage,
//   EventsPage,
//   FAQPage,
//   CheckoutPage,
//   PaymentPage,
//   OrderSuccessPage,
//   ProductDetailsPage,
//   ProfilePage,
//   ShopCreatePage,
//   SellerActivationPage,
//   ShopLoginPage,
//   OrderDetailsPage,
//   TrackOrderPage,
//   UserInbox,
// } from "./routes/Routes";

// import {
//   ShopDashboardPage,
//   ShopCreateProduct,
//   ShopAllProducts,
//   ShopCreateEvents,
//   ShopAllEvents,
//   ShopAllCoupouns,
//   ShopPreviewPage,
//   ShopAllOrders,
//   ShopOrderDetails,
//   ShopAllRefunds,
//   ShopSettingsPage,
//   ShopWithDrawMoneyPage,
//   ShopInboxPage,
// } from "./routes/ShopRoutes";

// import {
//   AdminDashboardPage,
//   AdminDashboardUsers,
//   AdminDashboardSellers,
//   AdminDashboardOrders,
//   AdminDashboardProducts,
//   AdminDashboardEvents,
//   AdminDashboardWithdraw,
// } from "./routes/AdminRoutes";

// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { loadSeller, loadUser } from "./redux/actions/user";
// import ProtectedRoute from "./routes/ProtectedRoute";
// import ProtectedAdminRoute from "./routes/ProtectedAdminRoute";
// import SellerProtectedRoute from "./routes/SellerProtectedRoute";
// import { ShopHomePage } from "./ShopRoutes";
// import { getAllProducts } from "./redux/actions/product";
// import { getAllEvents } from "./redux/actions/event";
// import { useGetStripeapikeyQuery } from "./redux/paymentApi";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import ForgotPassword from "./components/forgetpassword/ForgotPassword";
// import ResetPassword from "./components/forgetpassword/ResetPassword";
// import ShopResetPassword from "./components/Shop/ShopResetPassword";
// import ShopForgotPassword from "./components/Shop/ShopForgotPassword";
// const App = () => {
//   const [stripeApiKey, setStripeApiKey] = useState("");
//   const { data, isSuccess } = useGetStripeapikeyQuery();

//   useEffect(() => {
//     Store.dispatch(loadUser());
//     Store.dispatch(loadSeller());
//     Store.dispatch(getAllProducts());
//     Store.dispatch(getAllEvents());

//     if (isSuccess && data) {
//       const apiKey = data.stripeApikey; // Adjusted to correctly access the API key
//       if (typeof apiKey === 'string' && apiKey.trim() !== '') {
//         setStripeApiKey(apiKey); // Set the Stripe API key if valid
//       } else {
//         console.error("Invalid Stripe API key:", apiKey);
//       }
//     }
//   }, [isSuccess, data]);

//   return (
//     <BrowserRouter>
//       {/* Load Stripe Elements if API key is available */}
//       {stripeApiKey && (
//         <Elements stripe={loadStripe(stripeApiKey)}>
//           <Routes>
//             <Route path="/payment" element={<PaymentPage />} />
//           </Routes>
//         </Elements>
//       )}

//       <Routes>
//         <Route path="/" element={<HomePage />} />
//         <Route path="/login" element={<LoginPage />} />
//         <Route path="/sign-up" element={<SignupPage />} />
//         <Route path="/forgot-password" element={<ForgotPassword />} />
//         <Route path="/reset-password" element={<ResetPassword />} />

//         <Route path="/shop-reset-password" element={<ShopResetPassword />} />
//         <Route path="/shop-forgot-password" element={<ShopForgotPassword />} />

//         <Route path="/activation/:activation_token" element={<ActivationPage />} />
//         <Route path="/seller/activation/:activation_token" element={<SellerActivationPage />} />
//         <Route path="/products" element={<ProductsPage />} />
//         <Route path="/product/:id" element={<ProductDetailsPage />} />
//         <Route path="/best-selling" element={<BestSellingPage />} />
//         <Route path="/events" element={<EventsPage />} />
//         <Route path="/faq" element={<FAQPage />} />
//         <Route path="/checkout" element={<ProtectedRoute><CheckoutPage /></ProtectedRoute>} />
//         <Route path="/order/success" element={<OrderSuccessPage />} />
//         <Route path="/profile" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
//         <Route path="/inbox" element={<ProtectedRoute><UserInbox /></ProtectedRoute>} />
//         <Route path="/user/order/:id" element={<ProtectedRoute><OrderDetailsPage /></ProtectedRoute>} />
//         <Route path="/user/track/order/:id" element={<ProtectedRoute><TrackOrderPage /></ProtectedRoute>} />
//         <Route path="/shop/preview/:id" element={<ShopPreviewPage />} />

//         {/* Shop Routes */}
//         <Route path="/shop-create" element={<ShopCreatePage />} />
//         <Route path="/shop-login" element={<ShopLoginPage />} />
//         <Route path="/shop/:id" element={<SellerProtectedRoute><ShopHomePage /></SellerProtectedRoute>} />
//         <Route path="/settings" element={<SellerProtectedRoute><ShopSettingsPage /></SellerProtectedRoute>} />
//         <Route path="/dashboard" element={<SellerProtectedRoute><ShopDashboardPage /></SellerProtectedRoute>} />
//         <Route path="/dashboard-create-product" element={<SellerProtectedRoute><ShopCreateProduct /></SellerProtectedRoute>} />
//         <Route path="/dashboard-orders" element={<SellerProtectedRoute><ShopAllOrders /></SellerProtectedRoute>} />
//         <Route path="/dashboard-refunds" element={<SellerProtectedRoute><ShopAllRefunds /></SellerProtectedRoute>} />
//         <Route path="/order/:id" element={<SellerProtectedRoute><ShopOrderDetails /></SellerProtectedRoute>} />
//         <Route path="/dashboard-products" element={<SellerProtectedRoute><ShopAllProducts /></SellerProtectedRoute>} />
//         <Route path="/dashboard-withdraw-money" element={<SellerProtectedRoute><ShopWithDrawMoneyPage /></SellerProtectedRoute>} />
//         <Route path="/dashboard-messages" element={<SellerProtectedRoute><ShopInboxPage /></SellerProtectedRoute>} />
//         <Route path="/dashboard-create-event" element={<SellerProtectedRoute><ShopCreateEvents /></SellerProtectedRoute>} />
//         <Route path="/dashboard-events" element={<SellerProtectedRoute><ShopAllEvents /></SellerProtectedRoute>} />
//         <Route path="/dashboard-coupouns" element={<SellerProtectedRoute><ShopAllCoupouns /></SellerProtectedRoute>} />

//         {/* Admin Routes */}
//         <Route path="/admin/dashboard" element={<ProtectedAdminRoute><AdminDashboardPage /></ProtectedAdminRoute>} />
//         <Route path="/admin-users" element={<ProtectedAdminRoute><AdminDashboardUsers /></ProtectedAdminRoute>} />
//         <Route path="/admin-sellers" element={<ProtectedAdminRoute><AdminDashboardSellers /></ProtectedAdminRoute>} />
//         <Route path="/admin-orders" element={<ProtectedAdminRoute><AdminDashboardOrders /></ProtectedAdminRoute>} />
//         <Route path="/admin-products" element={<ProtectedAdminRoute><AdminDashboardProducts /></ProtectedAdminRoute>} />
//         <Route path="/admin-events" element={<ProtectedAdminRoute><AdminDashboardEvents /></ProtectedAdminRoute>} />
//         <Route path="/admin-withdraw-request" element={<ProtectedAdminRoute><AdminDashboardWithdraw /></ProtectedAdminRoute>} />
//       </Routes>

//       <ToastContainer
//         position="bottom-center"
//         autoClose={5000}
//         hideProgressBar={false}
//         newestOnTop={false}
//         closeOnClick
//         rtl={false}
//         pauseOnFocusLoss
//         draggable
//         pauseOnHover
//         theme="dark"
//       />
//     </BrowserRouter>
//   );
// };

// export default App;




import React, { useState, useEffect } from "react";
import "./App.css";
import Store from "./redux/store";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  LoginPage,
  SignupPage,
  ActivationPage,
  HomePage,
  ProductsPage,
  BestSellingPage,
  EventsPage,
  FAQPage,
  CheckoutPage,
  PaymentPage,
  OrderSuccessPage,
  ProductDetailsPage,
  ProfilePage,
  ShopCreatePage,
  SellerActivationPage,
  ShopLoginPage,
  OrderDetailsPage,
  TrackOrderPage,
  UserInbox,
} from "./routes/Routes";

import {
  ShopDashboardPage,
  ShopCreateProduct,
  ShopAllProducts,
  ShopCreateEvents,
  ShopAllEvents,
  ShopAllCoupouns,
  ShopPreviewPage,
  ShopAllOrders,
  ShopOrderDetails,
  ShopAllRefunds,
  ShopSettingsPage,
  ShopWithDrawMoneyPage,
  ShopInboxPage,
} from "./routes/ShopRoutes";

import {
  AdminDashboardPage,
  AdminDashboardUsers,
  AdminDashboardSellers,
  AdminDashboardOrders,
  AdminDashboardProducts,
  AdminDashboardEvents,
  AdminDashboardWithdraw,
} from "./routes/AdminRoutes";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loadSeller, loadUser } from "./redux/actions/user";
import ProtectedRoute from "./routes/ProtectedRoute";
import ProtectedAdminRoute from "./routes/ProtectedAdminRoute";
import SellerProtectedRoute from "./routes/SellerProtectedRoute";
import { ShopHomePage } from "./ShopRoutes";
import { getAllProducts } from "./redux/actions/product";
import { useGetStripeapikeyQuery } from "./redux/paymentApi";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ForgotPassword from "./components/forgetpassword/ForgotPassword";
import ResetPassword from "./components/forgetpassword/ResetPassword";
import ShopResetPassword from "./components/Shop/ShopResetPassword";
import ShopForgotPassword from "./components/Shop/ShopForgotPassword";
import PublicRoute from "./components/publicRoutes";
import {getAllEvents} from "./redux/actions/event";

import PrivateRoute from "./components/privateRoutes"
import ShopRoute from "./components/shopRoutes"
import SuccessPage from "./pages/SuccessPage";
const App = () => {
  const [stripeApiKey, setStripeApiKey] = useState("");
  const { data, isSuccess } = useGetStripeapikeyQuery();
  // const { getAllEventsShop, deleteEvent } = EventComponent(); // Use the imported functions
// const{getAllEvents}=EventComponent();
  useEffect(() => {
    Store.dispatch(loadUser());
    Store.dispatch(loadSeller());
    Store.dispatch(getAllProducts());
     Store.dispatch(getAllEvents());
    if (isSuccess && data) {
      const apiKey = data.stripeApikey;
      if (typeof apiKey === 'string' && apiKey.trim() !== '') {
        setStripeApiKey(apiKey);
      } else {
        console.error("Invalid Stripe API key:", apiKey);
      }
    }
  }, [isSuccess, data]);

  return (
    <BrowserRouter>
      {stripeApiKey && (
        <Elements stripe={loadStripe(stripeApiKey)}>
          <Routes>
            <Route path="/payment" element={<PaymentPage />} />
          </Routes>
        </Elements>
      )}

      <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/seller/activation/:activation_token" element={<SellerActivationPage />} />
      <Route path="/products" element={<ProductsPage />} />
          <Route path="/product/:id" element={<ProductDetailsPage />} />
          <Route path="/best-selling" element={<BestSellingPage />} />
          <Route path="/events" element={<EventsPage />} />
          <Route path="/faq" element={<FAQPage />} />
        <Route element={<PublicRoute />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/sign-up" element={<SignupPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/shop-reset-password" element={<ShopResetPassword />} />
          <Route path="/shop-forgot-password" element={<ShopForgotPassword />} />
          <Route path="/activation/:activation_token" element={<ActivationPage />} />
          <Route path="/shop-create" element={<ShopCreatePage />} />
          <Route path="/shop-login" element={<ShopLoginPage />} />
          <Route path="/shop/:id" element={<ShopHomePage />} />
          {/* <Route path="/seller/activation/:activation_token" element={<SellerActivationPage />} /> */}
         
        </Route>

        <Route element={<PrivateRoute />}>
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/ordersuccess" element={<SuccessPage/>}/>
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/inbox" element={<UserInbox />} />
          <Route path="/user/order/:id" element={<OrderDetailsPage />} />
          <Route path="/user/track/order/:id" element={<TrackOrderPage />} />
          <Route path="/shop/preview/:id" element={<ShopPreviewPage />} />
        {/* </Route> */}

        {/* Shop Routes */}
        {/* <Route path=""   element={<SellerProtectedRoute />}> */}
        </Route>
        <Route element={<ShopRoute />}>        
          <Route path="/settings" element={<ShopSettingsPage />} />
          <Route path="/dashboard" element={<ShopDashboardPage />} />
          <Route path="/dashboard-create-product" element={<ShopCreateProduct />} />
          <Route path="/dashboard-orders" element={<ShopAllOrders />} />
          <Route path="/dashboard-refunds" element={<ShopAllRefunds />} />
          <Route path="/order/:id" element={<ShopOrderDetails />} />
          <Route path="/dashboard-products" element={<ShopAllProducts />} />
          <Route path="/dashboard-withdraw-money" element={<ShopWithDrawMoneyPage />} />
          <Route path="/dashboard-messages" element={<ShopInboxPage />} />
          <Route path="/dashboard-create-event" element={<ShopCreateEvents />} />
          <Route path="/dashboard-events" element={<ShopAllEvents />} />
          <Route path="/dashboard-coupouns" element={<ShopAllCoupouns />} />
        </Route>

        {/* Admin Routes */}
        <Route   path="" element={<ProtectedAdminRoute />}>
          <Route path="/admin/dashboard" element={<AdminDashboardPage />} />
          <Route path="/admin-users" element={<AdminDashboardUsers />} />
          <Route path="/admin-sellers" element={<AdminDashboardSellers />} />
          <Route path="/admin-orders" element={<AdminDashboardOrders />} />
          <Route path="/admin-products" element={<AdminDashboardProducts />} />
          <Route path="/admin-events" element={<AdminDashboardEvents />} />
          <Route path="/admin-withdraw-request" element={<AdminDashboardWithdraw />} />
        </Route>
      </Routes>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </BrowserRouter>
  );
};

export default App;


