import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  shopInfo: localStorage.getItem('shopInfo')
    ? JSON.parse(localStorage.getItem('shopInfo'))
    : null,
};

const shopauthSlice = createSlice({
  name: 'shopauth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      state.shopInfo = action.payload;
      localStorage.setItem('shopInfo', JSON.stringify(action.payload));
    },
    setShopLogout: (state, action) => {
      state.userInfo = null;
      localStorage.removeItem('shopInfo');
    },
  },
});
 export const { setCredentials, setShopLogout } = shopauthSlice.actions;

 export default shopauthSlice.reducer;






 



