
import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { BsFillBagFill } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { backend_url } from "../../server";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateOrderStatusMutation, useOrderRefundSuccessMutation } from "../../redux/orderApi";

const OrderDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { orders, isLoading } = useSelector((state) => state.order);
  const { shopInfo } = useSelector((state) => state.shopauth);

  const [status, setStatus] = useState("");
  const [updateOrderStatus] = useUpdateOrderStatusMutation();
  const [orderRefundSuccess] = useOrderRefundSuccessMutation();

  useEffect(() => {
    dispatch(getAllOrdersOfShop(shopInfo.user._id));
  }, [dispatch, shopInfo.user._id]);

  const data = orders?.find((item) => item._id === id);

  useEffect(() => {
    if (data) {
      setStatus(data.status);
    }
  }, [data]);

  const orderUpdateHandler = async () => {
    try {
      await updateOrderStatus({ orderId: id, status }).unwrap();
      toast.success("Order updated!");
      navigate("/dashboard-orders");
    } catch (error) {
      toast.error(error.data?.message || "Failed to update order status");
    }
  };

  const refundOrderUpdateHandler = async () => {
    try {
      await orderRefundSuccess({ orderId: id, status }).unwrap();
      toast.success("Refund processed successfully!");
      dispatch(getAllOrdersOfShop(shopInfo.user._id));
    } catch (error) {
      toast.error(error.data?.message || "Failed to process refund");
    }
  };

  // List of all possible order statuses
  const orderStatusOptions = [
    "Processing",
    "Transferred to delivery partner",
    "Shipping",
    "Received",
    "On the way",
    "Delivered",
    "Processing refund",
    "Refund Success"
  ];

  return (
    <div className={`py-4 min-h-screen ${styles.section}`}>
      <div className="w-full flex items-center justify-between">
        <div className="flex items-center">
          <BsFillBagFill size={30} color="crimson" />
          <h1 className="pl-2 text-[25px]">Order Details</h1>
        </div>
        <Link to="/dashboard-orders">
          <div className={`${styles.button} !bg-[#fce1e6] !rounded-[4px] text-[#e94560] font-[600] !h-[45px] text-[18px]`}>
            Order List
          </div>
        </Link>
      </div>

      <div className="w-full flex items-center justify-between pt-6">
        <h5 className="text-[#00000084]">
          Order ID: <span>#{data?._id?.slice(0, 8)}</span>
        </h5>
        <h5 className="text-[#00000084]">
          Placed On: <span>{data?.createdAt?.slice(0, 10)}</span>
        </h5>
      </div>

      <br />
      <br />
      {data && data.cart.map((item, index) => (
        <div className="w-full flex items-start mb-5" key={index}>
          <img
            src={`${backend_url}/${item.images[0]}`}
            alt="Product item order img"
            className="w-[80px] h-[80px]"
          />
          <div className="w-full">
            <h5 className="pl-3 text-[20px]">{item.name}</h5>
            <h5 className="pl-3 text-[20px] text-[#00000091]">
              {item.discountPrice} x {item.qty}
            </h5>
          </div>
        </div>
      ))}

      <div className="border-t w-full text-right">
        <h5>
          Total Price: <strong>{data?.totalPrice}rs</strong>
        </h5>
      </div>
      <br />
      <br />

      {/* Shipping Address */}
      <div className="w-full 800px:flex items-center">
        <div className="w-full 800px:w-[60%]">
          <h4 className="pt-3 text-[20px] font-[600]">Shipping Address:</h4>
          <h4 className="pt-3 text-[20px]">
            {data?.shippingAddress.address1 + " " + data?.shippingAddress.address2}
          </h4>
          <h4 className="text-[20px]">{data?.shippingAddress.country}</h4>
          <h4 className="text-[20px]">{data?.shippingAddress.city}</h4>
          <h4 className="text-[20px]">{data?.user?.phoneNumber}</h4>
        </div>

        <div className="w-full 800px:w-[40%]">
          <h4 className="pt-3 text-[20px]">Payment Info:</h4>
          <h4>
            Status: {data?.paymentInfo?.status || "Not Paid"}
          </h4>
        </div>
      </div>
      <br />
      <br />

      <h4 className="pt-3 text-[20px] font-[600]">Order status:</h4>
      <select
        value={status}
        onChange={(e) => setStatus(e.target.value)}
        className="w-[200px] mt-2 border h-[35px] rounded-[5px]"
      >
        {orderStatusOptions.map((option, index) => (
          <option value={option} key={index}>
            {option}
          </option>
        ))}
      </select>

      <div
        className={`${styles.button} mt-5 !bg-[#FCE1E6] !rounded-[4px] text-[#E94560] font-[600] !h-[45px] text-[18px]`}
        onClick={status.includes("refund") ? refundOrderUpdateHandler : orderUpdateHandler}
      >
        Update Status
      </div>
    </div>
  );
};

export default OrderDetails;
