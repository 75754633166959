import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';


import { useUserActivationMutation } from '../redux/userApiSlice';

const ActivationPage = () => {
    const { activation_token } = useParams();
    const [error, setError] = useState(false);
const[userActivation]=useUserActivationMutation();
    useEffect(() => {
        if (activation_token) {
            const activationEmail = async () => {
                try {
                    const res = await userActivation({ activation_token
                    });
                    
                    console.log(res.data.message);
                } catch (err) {
                    console.log(err.response.data.message);
                    setError(true);
                }
            }
            activationEmail();
        }

    }, []);

    return (
        <div
            style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
            {
                error ? (
                    <p className='text-red-800'>Your toke is expair </p>
                ) : (
                    <p className='text-green-800'>Your Account has been created sucess fully!</p>
                )
            }

        </div>
    )
}

export default ActivationPage