
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define the base URL for the user API
const USERS_URL = 'https://api.viceindustries.in/api/v2/user';

// Create the user API slice
export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: USERS_URL,
    credentials: 'include', 
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: '/login-user', 
        method: 'POST',
        body: data,
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: '/logout',
        method: 'GET',
        credentials: "include"
      }),
    }),
signup: builder.mutation({
  query: (data) => ({
    url: '/create-user',
    method: 'post',
    body: data,
  }),
}),
userActivation: builder.mutation({
  query: (data) => ({
    url: '/activation', 
    method: 'post',
    body: data,
  }),
}),

forgotPassword: builder.mutation({
  query: (data) => ({
    url: '/forgot-password',
    method: 'POST',
    body: data,
    credentials: "include"

  }),
}),
resetPassword: builder.mutation({
  query: (data) => ({
    url: '/reset-password',
    method: 'POST',
    body: data,
    credentials: "include"

  }),
}),

changePassword: builder.mutation({
  query: (data) => ({
    url: '/update-user-password', 
    method: 'PUT',
    body: data,
  }),
}),

updateUserInfo: builder.mutation({
  query: (data) => ({
    url: '/update-user-info', 
    method: 'PUT',
    body: data,
  }),
}),


updateUserAddress: builder.mutation({
  query: (data) => ({
    url: '/update-user-address', 
    method: 'PUT',
    body: data,
  }),
}),
deleteUserAddress: builder.mutation({
  query: (data) => ({
    url: '/delete-user-address', 
    method: 'delete',
    body: data,
  }),
}),

getUserInfo: builder.query({
  query: (userId) => `/user-info/${userId}`,
}),
getUser: builder.query({
  query: () => "/get-user",
}),

getAdminAllUsers: builder.query({
  query: () => "/admin-all-user",
}),

  }),
});


export const {useChangePasswordMutation,useGetAdminAllUsersQuery,useDeleteUserAddressMutation, useUpdateUserInfoMutation,useGetUserQuery,useGetUserInfoQuery,useLoginMutation ,
  useSignupMutation,useUserActivationMutation,useResetPasswordMutation,useLogoutMutation,useForgotPasswordMutation,useUpdateUserAddressMutation} = userApi;


