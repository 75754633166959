
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/styles";
import {
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RxCross1 } from "react-icons/rx";
import { useCreateOrdersMutation, usePaymentProcessMutation } from "../../redux/paymentApi"; // Import your mutation hooks
import { server } from "../../server";
import axios from "axios";

const Payment = () => {
    const [orderData, setOrderData] = useState([]);
    const [open, setOpen] = useState(false);
    const { user } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const [createOrder] = useCreateOrdersMutation(); // Use the create order mutation
    const [paymentProcess] = usePaymentProcessMutation(); // Use the payment process mutation

    useEffect(() => {
        const orderData = JSON.parse(localStorage.getItem("latestOrder"));
        setOrderData(orderData);
    }, []);

    // PayPal
    const createOrderPaypal = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    description: "Sunflower",
                    amount: {
                        currency_code: "USD",
                        value: orderData?.totalPrice,
                    },
                },
            ],
            application_context: {
                shipping_preference: "NO_SHIPPING",
            },
        });
    };

    const order = {
        cart: orderData?.cart,
        shippingAddress: orderData?.shippingAddress,
        user: user && user,
        totalPrice: orderData?.totalPrice,
    };

    const onApprove = async (data, actions) => {
        return actions.order.capture().then(async (details) => {
            const { payer } = details;
            let paymentInfo = payer;

            if (paymentInfo !== undefined) {
                await paypalPaymentHandler(paymentInfo); // Call the handler
            }
        });
    };

    const paypalPaymentHandler = async (paymentInfo) => {
        order.paymentInfo = {
            id: paymentInfo.payer_id,
            status: "succeeded",
            type: "Paypal",
        };

        try {
            const response = await createOrder(order).unwrap(); // Use the mutation
            setOpen(false);
            navigate("/order/success");
            toast.success("Order successful!");
            localStorage.setItem("cartItems", JSON.stringify([]));
            localStorage.setItem("latestOrder", JSON.stringify([]));
            window.location.reload();
        } catch (error) {
            toast.error(error.message); // Handle errors
        }
    };

    const checkoutHandler = async (amount, name, id) => {
        
        axios.defaults.withCredentials = true;
        order.paymentInfo = {
            type: "Razorpay",
        };

        const {data:{razorpayApikey}} = await axios.get(`${server}/razorpay/razorpayapikey`);
    
        const {data:{norder}} = await axios.post(`${server}/razorpay/create-order`, {
            order
        })
        console.log(norder);
        
        var options = {
          key: razorpayApikey,
          amount: parseFloat(norder.totalPrice) * 100,
          currency: "INR",
          name: name,
          description: "Lincfolio Subscription Plan",
          image: "https://store.viceindustries.in/logo.png",
          order_id: norder.order_id,
          callback_url: `${server}/razorpay/paymentverification`,
          prefill:{
            name: user.firstname + ' ' + user.lastname,
            email: user.email,
          },
          theme: {
            color: "#2f3640"
          }
        }
        const razor = new window.Razorpay(options);
        razor.open();
      }

    // Cash on Delivery Handler (COD)
    const cashOnDeliveryHandler = async (e) => {
        e.preventDefault();
        order.paymentInfo = {
            type: "Cash On Delivery",
        };

        try {
            const response = await createOrder(order).unwrap(); // Use the mutation
            setOpen(false);
            navigate("/order/success");
            toast.success("Order successful!");
            localStorage.setItem("cartItems", JSON.stringify([]));
            localStorage.setItem("latestOrder", JSON.stringify([]));
            window.location.reload();
        } catch (error) {
            toast.error(error.message); // Handle errors
        }
    };

    return (
        <div className="w-full flex flex-col items-center py-8">
            <div className="w-[90%] 1000px:w-[70%] block 800px:flex">
                <div className="w-full 800px:w-[65%]">
                    <PaymentInfo
                        user={user}
                        open={open}
                        setOpen={setOpen}
                        onApprove={onApprove}
                        createOrder={createOrderPaypal}
                        checkoutHandler={checkoutHandler}
                        cashOnDeliveryHandler={cashOnDeliveryHandler}
                    />
                </div>
                <div className="w-full 800px:w-[35%] 800px:mt-0 mt-8">
                    <CartData
                        orderData={orderData}
                    />
                </div>
            </div>
        </div>
    );
};

const PaymentInfo = ({
    user,
    open,
    setOpen,
    onApprove,
    createOrder,
    checkoutHandler,
    cashOnDeliveryHandler,
}) => {
    const [select, setSelect] = useState(1);

    return (
        <div className="w-full 800px:w-[95%] bg-[#fff] rounded-md p-5 pb-8">
            {/* select buttons */}
            <div>
                <div className="flex w-full pb-5 border-b mb-2">
                    <div
                        className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] relative flex items-center justify-center"
                        onClick={() => setSelect(1)}
                    >
                        {select === 1 ? (
                            <div className="w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full" />
                        ) : null}
                    </div>
                    <h4 className="text-[18px] pl-2 font-[600] text-[#000000b1]">
                        Pay with Razorpay
                    </h4>
                </div>

                {/* pay with card */}
                {select === 1 ? (
                    <div className="w-full flex border-b">
                    <div
                        className={`${styles.button} !bg-[#f63b60] text-white h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`}
                        onClick={() => checkoutHandler()}
                    >
                        Pay Now
                    </div>
                    {open && (
                        <div className="w-full fixed top-0 left-0 bg-[#00000039] h-screen flex items-center justify-center z-[99999]">
                            <div className="w-full 800px:w-[40%] h-screen 800px:h-[80vh] bg-white rounded-[5px] shadow flex flex-col justify-center p-8 relative overflow-y-scroll">
                                <div className="w-full flex justify-end p-3">
                                    <RxCross1
                                        size={30}
                                        className="cursor-pointer absolute top-5 right-3"
                                        onClick={() => setOpen(false)}
                                    />
                                </div>
                                <PayPalScriptProvider
                                    options={{
                                        "client-id":
                                            "AXRhO4eNGo3L8MUFazEFnW9hNwBP2rTwUWNqMMRcFtjpbCrDVt6vS8HoWa7hyLlfO0fxG3OU_9zit7KN",
                                    }}
                                >
                                    <PayPalButtons
                                        style={{ layout: "vertical" }}
                                        onApprove={onApprove}
                                        createOrder={createOrder}
                                    />
                                </PayPalScriptProvider>
                            </div>
                        </div>
                    )}
                </div>
                ) : null}
            </div>

            <br />
            {/* paypal payment */}

            <br />
            {/* cash on delivery */}
            <div>
                <div className="flex w-full pb-5 border-b mb-2">
                    <div
                        className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] relative flex items-center justify-center"
                        onClick={() => setSelect(3)}
                    >
                        {select === 3 ? (
                            <div className="w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full" />
                        ) : null}
                    </div>
                    <h4 className="text-[18px] pl-2 font-[600] text-[#000000b1]">
                        Cash on Delivery
                    </h4>
                </div>

                {/* cash on delivery */}
                {select === 3 ? (
                    <div className="w-full flex">
                        <form className="w-full" onSubmit={cashOnDeliveryHandler}>
                            <input
                                type="submit"
                                value="Confirm"
                                className={`${styles.button} !bg-[#f63b60] text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`}
                            />
                        </form>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

const CartData = ({ orderData }) => {
    const shipping = orderData?.shipping?.toFixed(2);
    return (
        <div className="w-full bg-[#fff] rounded-md p-5 pb-8">
            <div className="flex justify-between">
                <h3 className="text-[16px] font-[400] text-[#000000a4]">subtotal:</h3>
                <h5 className="text-[18px] font-[600]">${orderData?.subTotalPrice}</h5>
            </div>
            <br />
            <div className="flex justify-between">
                <h3 className="text-[16px] font-[400] text-[#000000a4]">shipping:</h3>
                <h5 className="text-[18px] font-[600]">${shipping}</h5>
            </div>
            <br />
            <div className="flex justify-between border-b pb-3">
                <h3 className="text-[16px] font-[400] text-[#000000a4]">Discount:</h3>
                <h5 className="text-[18px] font-[600]">{orderData?.discountPrice ? "$" + orderData.discountPrice : "-"}</h5>
            </div>
            <h5 className="text-[18px] font-[600] text-end pt-3">
                ${orderData?.totalPrice}
            </h5>
            <br />
        </div>
    );
};






 export default Payment;




