import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const USERS_URL = 'https://api.viceindustries.in/api/v2';

export const messagesApi = createApi({
  reducerPath: 'messagesApi',
  baseQuery: fetchBaseQuery({ baseUrl:USERS_URL,    credentials: 'include',
  }),
  endpoints: (builder) => ({
    getConversations: builder.query({
      query: (sellerId) => `conversation/get-all-conversation-seller/${sellerId}`,
    }),
    getMessages: builder.query({
      query: (conversationId) => `message/get-all-messages/${conversationId}`,
    }),
    createMessage: builder.mutation({
      query: (data) => ({
        url: 'message/create-new-message',
        method: 'POST',
        body: data,
      }),
    }),
    updateLastMessages: builder.mutation({
      query: ({ chatId, newMessage, sellerId }) => ({
        url: `conversation/update-last-message/${chatId}`,
        method: 'PUT',
        body: {
          lastMessage: newMessage,
          lastMessageId: sellerId,
        },
      }),
    }),
    getUserInfo: builder.query({
      query: (userId) => `user/user-info/${userId}`,
    }),
  }),
});

export const {
  useGetConversationsQuery,
  useGetMessagesQuery,
  useCreateMessageMutation,
  useUpdateLastMessagesMutation,
  useGetUserInfoQuery,
} = messagesApi;
