import React, { useState } from 'react';
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux';

import { Link, useNavigate } from "react-router-dom";
// import { sendPasswordResetEmail } from '../../redux/userApiSlice'; // Adjust import as necessary
import styles from "../../styles/styles";
import { setCredentials } from '../../redux/authSlice';
import { useShopForgotPasswordMutation } from '../../redux/shopApiSlice';
const ShopForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const[shopforgotPassword]=useShopForgotPasswordMutation();
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const res = await shopforgotPassword({email});

            dispatch(setCredentials({ ...res }));
            
            toast.success("Check your email for reset instructions!");
        } catch (error) {
            toast.error(error.message || "Failed to send reset email");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
            <div className='sm:mx-auto sm:w-full sm:max-w-md'>
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    Forgot Password
                </h2>
            </div>
            <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
                <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
                    <form className='space-y-6' onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="email" className='block text-sm font-medium text-gray-700'>
                                Email address
                            </label>
                            <div className='mt-1'>
                                <input
                                    type="email"
                                    name='email'
                                    autoComplete='email'
                                    required
                                    placeholder='Enter your email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
                                />
                            </div>
                        </div>
                        <div>
                            <button
                                type='submit'
                                disabled={isLoading}
                                className={`group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${isLoading ? 'bg-gray-400' : 'bg-blue-600 hover:bg-blue-700'}`}
                            >
                                {isLoading ? "Sending..." : "Send  Email"}
                            </button>
                        </div>
                        <div className={`${styles.noramlFlex} w-full`}>
                            <Link to="/login" className="text-blue-600 pl-2">
                                Back to Login
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ShopForgotPassword;


