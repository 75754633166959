import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import ShopForgotPassword from '../components/Shop/ShopForgotPassword';

// Define the base URL for the user API
const USERS_URL = 'https://api.viceindustries.in/api/v2/shop';

// Create the user API slice
export const shopApi = createApi({
  reducerPath: 'shopApi',
  baseQuery: fetchBaseQuery({
    baseUrl: USERS_URL,
    credentials: 'include',
  }),
  endpoints: (builder) => ({
    shopLogin: builder.mutation({
      query: (data) => ({
        url: '/login-shop',
        method: 'POST',
        body: data,
      }),
    }),
    shopLogout: builder.mutation({
      query: (data) => ({
        url: '/logout',
        method: 'GET',
        body: data,
      }),
    }),
    
    shopSignup: builder.mutation({
      query: (data) => ({
        url: '/create-shop',
        method: 'POST',
        body: data,
      }),
    }),

    shopForgotPassword: builder.mutation({
      query: (data) => ({
        url: '/shop-forgot-password',
        method: 'POST',
        body: data,
      }),
    }),
    shopResetPassword: builder.mutation({
      query: (data) => ({
        url: '/shop-reset-password',
        method: 'POST',
        body: data,
      }),
    }),
    
    getShopInfo: builder.query({
      query: (Id) => `/get-shop-info/${Id}`,
    }),

    updateOrderStatus: builder.mutation({
      query: (data) => ({
        url: '/update-order-status',
        method: 'PUT',
        body: data,
      }),
    }),
    
    updateShopAvatar: builder.mutation({
      query: (data) => ({
        url: '/update-shop-avatar',
        method: 'PUT',
        body: data,
      }),
    }),
    
    updateSellerInfo: builder.mutation({
      query: (data) => ({
        url: '/update-seller-info',
        method: 'PUT',
        body: data,
      }),
    }),

    orderRefundSuccess: builder.mutation({
      query: (data) => ({
        url: '/order-refund-success',
        method: 'PUT',
        body: data,
      }),
    }),

    updatePaymentMethod: builder.mutation({
      query: (data) => ({
        url: '/update-payment-method',
        method: 'PUT',
        body: data,
      }),
    }),
    
    deleteWithdrawMethod: builder.mutation({
      query: (data) => ({
        url: '/delete-withdraw-method',
        method: 'DELETE',
        body: data,
      }),
    }),

    createWithdrawRequest: builder.mutation({
      query: (data) => ({
        url: '/create-withdraw-request',
        method: 'POST',
        body: data,
      }),
    }),

    getCoupons: builder.query({
      query: () => '/coupon/get-coupon/',
    }),


    getSellers: builder.query({
      query: () => '/get-sellers',
    }),

    deleteCoupons: builder.mutation({
      query: (data) => ({
        url: '/delete-coupons',
        method: 'DELETE',
        body: data,
      }),
    }),

    createCoupons: builder.mutation({
      query: (data) => ({
        url: '/create-coupons',
        method: 'POST',
        body: data,
      }),
    }),

    
  
    adminAllSellers: builder.query({
      query: () => "/admin-all-sellers", // Adjust the endpoint as needed
    }),
    getAllProductsShop: builder.query({
      query: (id) => `/get-all-products/${id}`, // Adjust the endpoint as needed
    }),
  shopActivation: builder.mutation({
    query: (data) => ({
      url: '/activation', 
      method: 'post',
      body: data,
    }),
  }),
}),
});

export const { 
  useShopLogoutMutation,
  useShopLoginMutation, 
  useAdminAllSellersQuery,
  useShopSignupMutation, 
  useGetShopInfoQuery, 
  useUpdateOrderStatusMutation,
  useUpdateShopAvatarMutation,
  useUpdateSellerInfoMutation,
  useOrderRefundSuccessMutation,
  useUpdatePaymentMethodMutation,
  useDeleteWithdrawMethodMutation,
  useCreateWithdrawRequestMutation,
  useGetCouponsQuery,
  useDeleteCouponsMutation,
  useCreateCouponsMutation,
  useShopForgotPasswordMutation,
  useShopResetPasswordMutation,
  useGetAllMessagesQuery,
  useShopActivationMutation,
  useGetAllProductsShopQuery,


  useGetSellersQuery
} = shopApi;
