
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define the base URL for the user API
const USERS_URL = 'https://api.viceindustries.in/api/v2/user';

// Create the user API slice
export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: fetchBaseQuery({//localhost:8000
    baseUrl: USERS_URL,
    credentials: 'include', 
  }),
  endpoints: (builder) => ({
    updateAvtar: builder.mutation({
      query: (data) => ({
        url: '/update-avatar', 
        method: 'PUT',
        body: data,
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: '/logout',
        method: 'GET',
        credentials: "include"
      }),
    }),
  }),
});


export const { useUpdateAvtarMutation,useLogoutMutation} = profileApi;


