// import React, { useState } from 'react';
// import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
// import styles from "../../styles/styles";
// import { Link, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import { RxAvatar } from 'react-icons/rx';
// import { useShopSignupMutation } from '../../redux/shopApiSlice';

// const ShopCreate = () => {
//     const navigate = useNavigate();
//     const [email, setEmail] = useState("");
//     const [name, setName] = useState("");
//     const [phoneNumber, setPhoneNumber] = useState("");
//     const [address, setAddress] = useState("");
//     const [zipCode, setZipCode] = useState("");
//     const [avatar, setAvatar] = useState(null);
//     const [password, setPassword] = useState("");
//     const [visible, setVisible] = useState(false);
    
//     // Use the shop signup mutation hook
//     const [shopSignup,{isLoading}] = useShopSignupMutation();

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         const newForm = new FormData();
//         newForm.append("file", avatar);
//         newForm.append("name", name);
//         newForm.append("email", email);
//         newForm.append("password", password);
//         newForm.append("zipCode", zipCode);
//         newForm.append("address", address);
//         newForm.append("phoneNumber", phoneNumber);

//         try {
//             const response = await shopSignup(newForm).unwrap();
//             toast.success(response.message);
//             // Reset form fields
//             setName("");
//             setEmail("");
//             setPassword("");
//             setAvatar(null);
//             setZipCode("");
//             setAddress("");
//             setPhoneNumber("");
//             navigate("/shop-login");
//         } catch (error) {
//             toast.error(error.message || "Signup failed");
//         }
//     };

//     const handleFileInputChange = (e) => {
//         const file = e.target.files[0];
//         setAvatar(file);
//     };

//     return (
//         <div className='min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
//             <div className='sm:mx-auto sm:w-full sm:max-w-md'>
//                 <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
//                     Register as a seller
//                 </h2>
//             </div>
//             <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-[35rem]'>
//                 <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
//                     <form className='space-y-6' onSubmit={handleSubmit}>
//                         {/* Shop Name */}
//                         <div>
//                             <label htmlFor="name" className='block text-sm font-medium text-gray-700'>
//                                 Shop Name
//                             </label>
//                             <div className='mt-1'>
//                                 <input
//                                     type="text"
//                                     name='name'
//                                     required
//                                     value={name}
//                                     onChange={(e) => setName(e.target.value)}
//                                     className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
//                                 />
//                             </div>
//                         </div>
                        
//                         {/* Phone Number */}
//                         <div>
//                             <label htmlFor="phoneNumber" className='block text-sm font-medium text-gray-700'>
//                                 Phone Number
//                             </label>
//                             <div className='mt-1'>
//                                 <input
//                                     type="tel"
//                                     name='phoneNumber'
//                                     required
//                                     value={phoneNumber}
//                                     onChange={(e) => setPhoneNumber(e.target.value)}
//                                     className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
//                                 />
//                             </div>
//                         </div>

//                         {/* Email */}
//                         <div>
//                             <label htmlFor="email" className="block text-sm font-medium text-gray-700">
//                                 Email Address
//                             </label>
//                             <div className="mt-1">
//                                 <input
//                                     type="email"
//                                     name="email"
//                                     autoComplete="email"
//                                     required
//                                     value={email}
//                                     onChange={(e) => setEmail(e.target.value)}
//                                     className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
//                                 />
//                             </div>
//                         </div>

//                         {/* Address */}
//                         <div>
//                             <label htmlFor="address" className="block text-sm font-medium text-gray-700">
//                                 Address
//                             </label>
//                             <div className="mt-1">
//                                 <input
//                                     type="text"
//                                     name="address"
//                                     required
//                                     value={address}
//                                     onChange={(e) => setAddress(e.target.value)}
//                                     className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
//                                 />
//                             </div>
//                         </div>

//                         {/* Zip Code */}
//                         <div>
//                             <label htmlFor="zipCode" className="block text-sm font-medium text-gray-700">
//                                 Zip Code
//                             </label>
//                             <div className="mt-1">
//                                 <input
//                                     type="text"
//                                     name="zipCode"
//                                     required
//                                     value={zipCode}
//                                     onChange={(e) => setZipCode(e.target.value)}
//                                     className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
//                                 />
//                             </div>
//                         </div>

//                         {/* Password */}
//                         <div>
//                             <label htmlFor="password" className="block text-sm font-medium text-gray-700">
//                                 Password
//                             </label>
//                             <div className="mt-1 relative">
//                                 <input
//                                     type={visible ? "text" : "password"}
//                                     name="password"
//                                     autoComplete="current-password"
//                                     required
//                                     value={password}
//                                     onChange={(e) => setPassword(e.target.value)}
//                                     className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
//                                 />
//                                 {visible ? (
//                                     <AiOutlineEye
//                                         className="absolute right-2 top-2 cursor-pointer"
//                                         size={25}
//                                         onClick={() => setVisible(false)}
//                                     />
//                                 ) : (
//                                     <AiOutlineEyeInvisible
//                                         className="absolute right-2 top-2 cursor-pointer"
//                                         size={25}
//                                         onClick={() => setVisible(true)}
//                                     />
//                                 )}
//                             </div>
//                         </div>

//                         {/* Avatar Upload */}
//                         <div>
//                             <label htmlFor="avatar" className="block text-sm font-medium text-gray-700"></label>
//                             <div className="mt-2 flex items-center">
//                                 <span className="inline-block h-8 w-8 rounded-full overflow-hidden">
//                                     {avatar ? (
//                                         <img
//                                             src={URL.createObjectURL(avatar)}
//                                             alt="avatar"
//                                             className="h-full w-full object-cover rounded-full"
//                                         />
//                                     ) : (
//                                         <RxAvatar className="h-8 w-8" />
//                                     )}
//                                 </span>
//                                 <label
//                                     htmlFor="file-input"
//                                     className="ml-5 flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
//                                 >
//                                     <span>Upload a file</span>
//                                     <input
//                                         type="file"
//                                         name="avatar"
//                                         id="file-input"
//                                         onChange={handleFileInputChange}
//                                         className="sr-only"
//                                     />
//                                 </label>
//                             </div>
//                         </div>

//                         <div>
//                             <button
//                                 type='submit'
//                                 className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
//                             >
//                                 {isLoading?"please wait":"submit"}
//                             </button>
//                         </div>

//                         <div className={`${styles.noramlFlex} w-full`}>
//                             <h4>Already have an account?</h4>
//                             <Link to="/shop-login" className="text-blue-600 pl-2">
//                                 Sign In
//                             </Link>
//                         </div>
//                     </form>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default ShopCreate;









import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { RxAvatar } from 'react-icons/rx';
import { useShopSignupMutation } from '../../redux/shopApiSlice';
import { setCredentials } from "../../redux/shopAuthSlice";
const ShopCreate = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [address, setAddress] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [avatar, setAvatar] = useState(null);
    const [password, setPassword] = useState("");
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();

    // Use the shop signup mutation hook
    const [shopSignup,{isLoading}] = useShopSignupMutation();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newForm = new FormData();
        newForm.append("file", avatar);
        newForm.append("name", name);
        newForm.append("email", email);
        newForm.append("password", password);
        newForm.append("zipCode", zipCode);
        newForm.append("address", address);
        newForm.append("phoneNumber", phoneNumber);

        try {
            const res = await shopSignup(newForm).unwrap();
            
            dispatch(setCredentials({ ...res }));

            toast.success(res.message);
            // Reset form fields
            setName("");
            setEmail("");
            setPassword("");
            setAvatar(null);
            setZipCode("");
            setAddress("");
            setPhoneNumber("");
            navigate("/shop-login");
        } catch (error) {
            toast.error(error.message || "Signup failed");
        }
    };

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        setAvatar(file);
    };

    return (
        <div className='min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
            <div className='sm:mx-auto sm:w-full sm:max-w-md'>
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    Register as a seller
                </h2>
            </div>
            <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-[35rem]'>
                <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
                    <form className='space-y-6' onSubmit={handleSubmit}>
                        {/* Shop Name */}
                        <div>
                            <label htmlFor="name" className='block text-sm font-medium text-gray-700'>
                                Shop Name
                            </label>
                            <div className='mt-1'>
                                <input
                                    type="text"
                                    name='name'
                                    required
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
                                />
                            </div>
                        </div>
                        
                        {/* Phone Number */}
                        <div>
                            <label htmlFor="phoneNumber" className='block text-sm font-medium text-gray-700'>
                                Phone Number
                            </label>
                            <div className='mt-1'>
                                <input
                                    type="tel"
                                    name='phoneNumber'
                                    required
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
                                />
                            </div>
                        </div>

                        {/* Email */}
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email Address
                            </label>
                            <div className="mt-1">
                                <input
                                    type="email"
                                    name="email"
                                    autoComplete="email"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        {/* Address */}
                        <div>
                            <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                                Address
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="address"
                                    required
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        {/* Zip Code */}
                        <div>
                            <label htmlFor="zipCode" className="block text-sm font-medium text-gray-700">
                                Zip Code
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="zipCode"
                                    required
                                    value={zipCode}
                                    onChange={(e) => setZipCode(e.target.value)}
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        {/* Password */}
                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Password
                            </label>
                            <div className="mt-1 relative">
                                <input
                                    type={visible ? "text" : "password"}
                                    name="password"
                                    autoComplete="current-password"
                                    required
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                                {visible ? (
                                    <AiOutlineEye
                                        className="absolute right-2 top-2 cursor-pointer"
                                        size={25}
                                        onClick={() => setVisible(false)}
                                    />
                                ) : (
                                    <AiOutlineEyeInvisible
                                        className="absolute right-2 top-2 cursor-pointer"
                                        size={25}
                                        onClick={() => setVisible(true)}
                                    />
                                )}
                            </div>
                        </div>

                        {/* Avatar Upload */}
                        <div>
                            <label htmlFor="avatar" className="block text-sm font-medium text-gray-700"></label>
                            <div className="mt-2 flex items-center">
                                <span className="inline-block h-8 w-8 rounded-full overflow-hidden">
                                    {avatar ? (
                                        <img
                                            src={URL.createObjectURL(avatar)}
                                            alt="avatar"
                                            className="h-full w-full object-cover rounded-full"
                                        />
                                    ) : (
                                        <RxAvatar className="h-8 w-8" />
                                    )}
                                </span>
                                <label
                                    htmlFor="file-input"
                                    className="ml-5 flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                                >
                                    <span>Upload a file</span>
                                    <input
                                        type="file"
                                        name="avatar"
                                        id="file-input"
                                        onChange={handleFileInputChange}
                                        className="sr-only"
                                    />
                                </label>
                            </div>
                        </div>

                        <div>
                            <button
                                type='submit'
                                className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                            >
                                {isLoading?"please wait":"submit"}
                            </button>
                        </div>

                        <div className={`${styles.noramlFlex} w-full`}>
                            <h4>Already have an account?</h4>
                            <Link to="/shop-login" className="text-blue-600 pl-2">
                                Sign In
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ShopCreate;
