// import { Button } from "@material-ui/core";
// import { DataGrid } from "@material-ui/data-grid";
// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
// import Loader from "../Layout/Loader";
// import { getAllOrdersOfShop } from "../../redux/actions/order";
// import { AiOutlineArrowRight } from "react-icons/ai";

// const AllRefundOrders = () => {
//     const { orders, isLoading } = useSelector((state) => state.order);
//     const { seller } = useSelector((state) => state.seller);

//     const dispatch = useDispatch();

//     useEffect(() => {
//         dispatch(getAllOrdersOfShop(seller._id));
//     }, [dispatch]);

//     const refundOrders = orders && orders.filter((item) => item.status === "Processing refund" || item.status === "Refund Success");

//     const columns = [
//         { field: "id", headerName: "Order ID", minWidth: 150, flex: 0.7 },

//         {
//             field: "status",
//             headerName: "Status",
//             minWidth: 130,
//             flex: 0.7,
//             cellClassName: (params) => {
//                 return params.getValue(params.id, "status") === "Delivered"
//                     ? "greenColor"
//                     : "redColor";
//             },
//         },
//         {
//             field: "itemsQty",
//             headerName: "Items Qty",
//             type: "number",
//             minWidth: 130,
//             flex: 0.7,
//         },

//         {
//             field: "total",
//             headerName: "Total",
//             type: "number",
//             minWidth: 130,
//             flex: 0.8,
//         },

//         {
//             field: " ",
//             flex: 1,
//             minWidth: 150,
//             headerName: "",
//             type: "number",
//             sortable: false,
//             renderCell: (params) => {
//                 return (
//                     <>
//                         <Link to={`/order/${params.id}`}>
//                             <Button>
//                                 <AiOutlineArrowRight size={20} />
//                             </Button>
//                         </Link>
//                     </>
//                 );
//             },
//         },
//     ];

//     const row = [];

//     refundOrders &&
//         refundOrders.forEach((item) => {
//             row.push({
//                 id: item._id,
//                 itemsQty: item.cart.length,
//                 total: "US$ " + item.totalPrice,
//                 status: item.status,
//             });
//         });

//     return (
//         <>
//             {isLoading ? (
//                 <Loader />
//             ) : (
//                 <div className="w-full mx-8 pt-1 mt-10 bg-white">
//                     <DataGrid
//                         rows={row}
//                         columns={columns}
//                         pageSize={10}
//                         disableSelectionOnClick
//                         autoHeight
//                     />
//                 </div>
//             )}
//         </>
//     );
// };

// export default AllRefundOrders;



// import { Button } from "@material-ui/core";
// import { DataGrid } from "@material-ui/data-grid";
// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
// import Loader from "../Layout/Loader";
// import { getAllOrdersOfShop } from "../../redux/actions/order";
// import { AiOutlineArrowRight } from "react-icons/ai";

// const AllRefundOrders = () => {
//     const { orders, isLoading } = useSelector((state) => state.order);
//     const { shopInfo } = useSelector((state) => state.shopauth);
//    console.log(orders);
   
//     const dispatch = useDispatch();

//     useEffect(() => {

//         dispatch(getAllOrdersOfShop(shopInfo.user._id));
//     }, [dispatch,shopInfo.user._id]);

//     const refundOrders = orders && orders.filter((item) => item.status === "Processing refund" || item.status === "Refund Success");

//     const columns = [
//         { field: "id", headerName: "Order ID", minWidth: 150, flex: 0.7 },

//         {
//             field: "status",
//             headerName: "Status",
//             minWidth: 130,
//             flex: 0.7,
//             cellClassName: (params) => {
//                 return params.getValue(params.id, "status") === "Delivered"
//                     ? "greenColor"
//                     : "redColor";
//             },
//         },
//         {
//             field: "itemsQty",
//             headerName: "Items Qty",
//             type: "number",
//             minWidth: 130,
//             flex: 0.7,
//         },

//         {
//             field: "total",
//             headerName: "Total",
//             type: "number",
//             minWidth: 130,
//             flex: 0.8,
//         },

//         {
//             field: " ",
//             flex: 1,
//             minWidth: 150,
//             headerName: "",
//             type: "number",
//             sortable: false,
//             renderCell: (params) => {
//                 return (
//                     <>
//                         <Link to={`/order/${params.id}`}>
//                             <Button>
//                                 <AiOutlineArrowRight size={20} />
//                             </Button>
//                         </Link>
//                     </>
//                 );
//             },
//         },
//     ];

//     const row = [];

//     refundOrders&&
//         refundOrders.forEach((item) => {
//             row.push({
//                 id: item._id,
//                 itemsQty: item.cart.length,
//                 total: "US$ " + item.totalPrice,
//                 status: item.status,
//             });
//         });

//     return (
//         <>
//             {isLoading ? (
//                 <Loader />
//             ) : (
//                 <div className="w-full mx-8 pt-1 mt-10 bg-white">
//                     <DataGrid
//                         rows={row}
//                         columns={columns}
//                         pageSize={10}
//                         disableSelectionOnClick
//                         autoHeight
//                     />
//                 </div>
//             )}
//         </>
//     );
// };

// export default AllRefundOrders;


import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../Layout/Loader";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import { AiOutlineArrowRight } from "react-icons/ai";

const AllRefundOrders = () => {
    const { orders, isLoading } = useSelector((state) => state.order);
    const { shopInfo } = useSelector((state) => state.shopauth);
    
    const dispatch = useDispatch();

    useEffect(() => {
        if (shopInfo?.user?._id) {
            dispatch(getAllOrdersOfShop(shopInfo.user._id));
        }
    }, [dispatch, shopInfo?.user?._id]);

    // Log all orders for inspection
    console.log('Orders:', orders);

    // const refundOrders = orders && orders.filter((item) => 
    //     item.status.toLowerCase() === "processing refund" || item.status.toLowerCase() === "refund success"
    // );

    // Log refundOrders to verify the filter works
    // console.log('Refund Orders:', refundOrders);

    const columns = [
        { field: "id", headerName: "Order ID", minWidth: 150, flex: 0.7 },
        {
            field: "status",
            headerName: "Status",
            minWidth: 130,
            flex: 0.7,
            cellClassName: (params) => {
                return params.getValue(params.id, "status") === "Delivered"
                    ? "greenColor"
                    : "redColor";
            },
        },
        {
            field: "itemsQty",
            headerName: "Items Qty",
            type: "number",
            minWidth: 130,
            flex: 0.7,
        },
        {
            field: "total",
            headerName: "Total",
            type: "number",
            minWidth: 130,
            flex: 0.8,
        },
        {
            field: " ",
            flex: 1,
            minWidth: 150,
            headerName: "",
            type: "number",
            sortable: false,
            renderCell: (params) => (
                <Link to={`/order/${params.id}`}>
                    <Button>
                        <AiOutlineArrowRight size={20} />
                    </Button>
                </Link>
            ),
        },
    ];

    const row = [];

    orders && orders.forEach((item) => {
        // Log each item to ensure proper data
        console.log('Refund Order Item:', item);

        row.push({
            id: item._id,               // Ensure _id exists
            itemsQty: item.cart.length,  // Ensure cart exists and has length
            total: "US$ " + item.totalPrice,
            status: item.status || "Unknown",  // Handle missing status case
        });
    });

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div className="w-full mx-8 pt-1 mt-10 bg-white">
                    <DataGrid
                        rows={row}
                        columns={columns}
                        pageSize={10}
                        disableSelectionOnClick
                        autoHeight
                    />
                </div>
            )}
        </>
    );
};

export default AllRefundOrders;
