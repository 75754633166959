import React, { useEffect, useState } from "react";
import { useDeleteShopEventMutation } from "../../redux/EventApi"; // Assuming you have a mutation defined for deleting an event

const CountDown = ({ data }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [deleteShopEvent] = useDeleteShopEventMutation(); // Mutation for deleting the event

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
      
      // Check if the countdown has reached zero
      if (
        typeof timeLeft.days === "undefined" &&
        typeof timeLeft.hours === "undefined" &&
        typeof timeLeft.minutes === "undefined" &&
        typeof timeLeft.seconds === "undefined"
      ) {
        deleteShopEvent(data._id); // Use mutation to delete event
        clearInterval(timer); // Clear interval once the event is deleted
      }
    }, 1000);

    return () => clearInterval(timer); // Cleanup interval on unmount
  }, [data, timeLeft, deleteShopEvent]);

  function calculateTimeLeft() {
    const difference = +new Date(data.Finish_Date) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  }

  const timerComponents = Object.keys(timeLeft).map((interval) => {
    if (!timeLeft[interval]) {
      return null;
    }

    return (
      <span className="text-[25px] text-[#475ad2]">
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  return (
    <div>
      {timerComponents.length ? (
        timerComponents
      ) : (
        <span className="text-[red] text-[25px]">Time's Up</span>
      )}
    </div>
  );
};

export default CountDown;
