
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define the base URL for the user API
const USERS_URL = 'https://api.viceindustries.in/api/v2';

// Create the user API slice
export const adminApi = createApi({
  reducerPath: 'adminApi',
  baseQuery: fetchBaseQuery({
    baseUrl: USERS_URL,
    credentials: 'include', 
  }),
  endpoints: (builder) => ({
    getAdminAllEvent: builder.query({
        query: () => '/event/admin-all-events',
      }),
      getAdminAllProduct: builder.query({
        query: () => '/product/admin-all-products',
      }),
      deleteUsers: builder.mutation({
        query: (data) => ({
          url: '/delete-users',
          method: 'DELETE',
          body: data,
        }),
      }),
      deleteSeller: builder.mutation({
        query: (data) => ({
          url: '/shop/delete-seller',
          method: 'DELETE',
          body: data,
        }),
      }),
      getAllWithdrawRequest: builder.query({
        query: () => '/withdraw/get-all-withdraw-request',
      }),
      updateAllWithdrawRequest: builder.mutation({
        query: (data) => ({
          url: '/withdraw/update-all-withdraw-request',
          method: 'update',
          body: data,
        }),
      }),
  }),
});


export const { useGetAdminAllEventQuery,
    useGetAdminAllProductQuery,
    useDeleteUsersMutation,
    useGetAllWithdrawRequestQuery,
useDeleteSellerMutation,
    useUpdateAllWithdrawRequestMutation} = adminApi;


