
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define the base URL for the user API
const USERS_URL = 'https://api.viceindustries.in/api/v2/event';

// Create the user API slice
export const eventApi = createApi({
  reducerPath: 'eventApi',
  baseQuery: fetchBaseQuery({
    baseUrl: USERS_URL,
    credentials: 'include', 
  }),
  endpoints: (builder) => ({
    deleteShopEvent: builder.mutation({
      query: (id) => ({
        url: `/delete-shop-event/${id}`,
        method: 'delete',
      }),
    }),
    getAdminEvent: builder.query({
      query: () => '/admin-all-event',
    }),

    getAllEvents: builder.query({
    query:(id)=> `/get-all-events/${id}`,
    }),

    getAllEventsShop: builder.query({
      query:(id)=> `/get-all-events/${id}`,
      }),
  

    getAdminProduct: builder.query({
      query: () => '/admin-all-product',
    }),
    createEvent: builder.mutation({
      query: (data) => ({
        url: '/create-event', 
        method: 'post',
        body: data,
      }),
    }),
       createEvent: builder.mutation({
      query: (data) => ({
        url: '/create-event', 
        method: 'post',
        body: data,
      }),
    }),
  }),
});


export const {useCreateEventMutation,useGetAllEventsShopQuery,useGetAllEventsQuery, useDeleteShopEventMutation,useGetAdminEventQuery,useGetAdminProductQuery} = eventApi;


