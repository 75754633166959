
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCreateReviewMutation, useUpdateOrderRefundMutation } from "../redux/orderApi"; // Import both mutations
import { getAllOrdersOfUser } from "../redux/actions/order";
import { toast } from "react-toastify";
import { BsFillBagFill } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { backend_url, server } from "../server";
import styles from "../styles/styles";
import axios from "axios";
const UserOrderDetails = () => {
  const { orders } = useSelector((state) => state.order);
  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [rating, setRating] = useState(1);

  const { id } = useParams();
  const [updateOrderRefund] = useUpdateOrderRefundMutation();
  const [createReview] = useCreateReviewMutation();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(userInfo .user._id));
  }, [dispatch, userInfo.user._id]);

  const data = orders && orders.find((item) => item._id === id);

  const reviewHandler = async (type) => {
    try {
      const endpoint =
        type === "product"
          ? "/product/create-new-review"
          : "/event/create-new-review-event";

      const res = await axios.put(
        `${server}${endpoint}`,
        {
          userInfo,
          rating,
          comment,
          productId: selectedItem?._id,
          orderId: id,
        },
        { withCredentials: true }
      );

      toast.success(res.data.message);
      dispatch(getAllOrdersOfUser(userInfo._id));
      setComment("");
      setRating(null);
      setOpen(false);
    } catch (error) {
      console.error(error); // Log the error to the console for debugging
      toast.error("An error occurred. Please try again."); // Display a generic error message
    }
  };

  const combinedHandler = async () => {
    if (rating > 1) {
      await reviewHandler("product");
      await reviewHandler("event");
    }
  };

  // Refund
  const refundHandler = async () => {
    try {
      const res = await updateOrderRefund({ id, status: "Processing refund" }).unwrap();
      toast.success(res.message);
      dispatch(getAllOrdersOfUser(userInfo.user._id));
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className={`py-4 min-h-screen ${styles.section}`}>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <BsFillBagFill size={30} color="crimson" />
          <h1 className="pl-2 text-[25px]">Order Details</h1>
        </div>
      </div>

      <div className="w-full flex items-center justify-between pt-6">
        <h5 className="text-[#00000084]">
          Order ID: <span>#{data?._id?.slice(0, 8)}</span>
        </h5>
        <h5 className="text-[#00000084]">
          Placed On: <span>{data?.createdAt?.slice(0, 10)}</span>
        </h5>
      </div>

      <br />
      <br />
      {data &&
        data.cart.map((item) => (
          <div key={item._id} className="w-full flex items-start mb-5">
            <img
              src={`${backend_url}/${item.images[0]}`}
              alt="Product item order img"
              className="w-[80px] h-[80px]"
            />
            <div className="w-full">
              <h5 className="pl-3 text-[20px]">{item.name}</h5>
              <h5 className="pl-3 text-[20px] text-[#00000091]">
                US${item.discountPrice} x {item.qty}
              </h5>
            </div>
            {!item.isReviewed && data.status === "Delivered" ? (
              <div
                className={`${styles.button} text-[#fff]`}
                onClick={() => {
                  setOpen(true);
                  setSelectedItem(item);
                }}
              >
                Write a review
              </div>
            ) : null}
          </div>
        ))}

      {/* Review Popup */}
      {open && (
        <div className="fixed top-0 left-0 w-full h-screen bg-[#0005] z-50 flex items-center justify-center">
          <div className="w-[50%] bg-[#fff] shadow rounded-md p-3">
            <div className="flex justify-end p-3">
              <RxCross1 size={30} onClick={() => setOpen(false)} className="cursor-pointer" />
            </div>
            <h2 className="text-[30px] font-[500] text-center">Give a Review</h2>
            <br />
            <div className="w-full flex">
              <img
                src={`${backend_url}/${selectedItem?.images[0]}`}
                alt=""
                className="w-[80px] h-[80px]"
              />
              <div>
                <div className="pl-3 text-[20px]">{selectedItem?.name}</div>
                <h4 className="pl-3 text-[20px]">
                  US${selectedItem?.discountPrice} x {selectedItem?.qty}
                </h4>
              </div>
            </div>

            <br />
            <br />

            {/* Rating */}
            <h5 className="pl-3 text-[20px] font-[500]">
              Give a Rating <span className="text-red-500">*</span>
            </h5>
            <div className="flex w-fit ml-2 pt-1">
              {[1, 2, 3, 4, 5].map((i) =>
                rating >= i ? (
                  <AiFillStar
                    key={i}
                    className="mr-1 cursor-pointer"
                    color="rgb(246,186,0)"
                    size={25}
                    onClick={() => setRating(i)}
                  />
                ) : (
                  <AiOutlineStar
                    key={i}
                    className="mr-1 cursor-pointer"
                    color="rgb(246,186,0)"
                    size={25}
                    onClick={() => setRating(i)}
                  />
                )
              )}
            </div>
            <br />
            {/* Comment */}
            <div className="w-full ml-3">
              <label className="block text-[20px] font-[500]">
                Write a Comment
                <span className="ml-1 font-[400] text-[16px] text-[#00000052]">
                  (Optional)
                </span>
              </label>
              <textarea
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="How was your product? Write your expression about it!"
                className="mt-2 w-[95%] border p-2 outline-none"
              ></textarea>
            </div>
            <div
              className={`${styles.button} text-white text-[20px] ml-3`}
              onClick={rating > 1 ? combinedHandler : null}
            >
              Submit
            </div>
          </div>
        </div>
      )}

      <div className="border-t w-full text-right">
        <h5>
          Total Price: <strong>US${data?.totalPrice}</strong>
        </h5>
      </div>
      <br />
      <br />

      {/* Shipping Address */}
      <div className="w-full flex items-center">
        <div className="w-full lg:w-[60%]">
          <h4 className="pt-3 text-[20px] font-[600]">Shipping Address:</h4>
          <h4 className="pt-3 text-[20px]">
            {data?.shippingAddress.address1} {data?.shippingAddress.address2}
          </h4>
          <h4 className="text-[20px]">{data?.shippingAddress.country}</h4>
          <h4 className="text-[20px]">{data?.shippingAddress.city}</h4>
          <h4 className="text-[20px]">{data?.user?.phoneNumber}</h4>
        </div>

        <div className="w-full lg:w-[40%]">
          <h4 className="pt-3 text-[20px]">Payment Info:</h4>
          <h4>
            Status: {data?.paymentInfo?.status || "Not Paid"}
          </h4>
          <br />
          {data?.status === "Delivered" && (
            <div
              className={`${styles.button} text-white`}
              onClick={refundHandler}
            >
              Request Refund
            </div>
          )}
        </div>
      </div>
      <br />

      <Link to="/">
        <div className={`${styles.button} text-white`}>Send Message</div>
      </Link>
      <br />
      <br />
    </div>
  );
};

export default UserOrderDetails;
