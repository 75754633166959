// import React from "react";
// import { useSelector } from "react-redux";
// import EventCard from "../components/Events/EventCard";
// import Header from "../components/Layout/Header";
// import Loader from "../components/Layout/Loader";

// const EventsPage = () => {
//   const { allEvents, isLoading } = useSelector((state) => state.events);
//   return (
//     <>
//       {isLoading ? (
//         <Loader />
//       ) : (
//         <div>
//           <Header activeHeading={4} />
//           {allEvents.length !== 0 ? (
//             allEvents.map((event) => (
//               <EventCard key={event.id} active={true} data={event} />
//             ))
//           ) : (
//             <p>No events available.</p>
//           )}
//         </div>
//       )}
//     </>
//   );
// };

// export default EventsPage;
import React from "react";
import { useSelector } from "react-redux";
import EventCard from "../components/Events/EventCard";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";

const EventsPage = () => {
  const { allEvents = [], isLoading } = useSelector((state) => state.events); // Default to an empty array

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Header activeHeading={4} />
          {allEvents.length > 0 ? ( // Check if there are events
            allEvents.map((event) => (
              <EventCard key={event.id} active={true} data={event} />
            ))
          ) : (
            <p>No events available.</p>
          )}
        </div>
      )}
    </>
  );
};

export default EventsPage;
