
import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getAllProductsShop } from "../../redux/actions/product";
import { backend_url, server } from "../../server";
import styles from "../../styles/styles";
import Loader from "../Layout/Loader";
import { useGetShopInfoQuery, useShopLogoutMutation } from '../../redux/shopApiSlice';
import { setShopLogout } from "../../redux/shopAuthSlice";
import { toast } from "react-toastify";
const ShopInfo = ({ isOwner }) => {


    const { shopInfo } = useSelector((state) => state.shopauth);


    const { products } = useSelector((state) => state.products);
    const { id } = useParams();
    const dispatch = useDispatch();
console.log(id);

    // Use the custom query hook to fetch shop info
    const { data: shopinfo, isLoading, isError } = useGetShopInfoQuery(id);
    const [shopLogout] = useShopLogoutMutation(); // Using the logout mutation


    console.log("shopinfo",shopinfo);


    useEffect(() => {
        dispatch(getAllProductsShop(id));
    }, [dispatch, id]);
// const ShopInfo = ({ isOwner }) => {
//     const { shopInfo } = useSelector((state) => state.shopauth);
//     const { products } = useSelector((state) => state.products);
//     const dispatch = useDispatch();
//     const { id } = useParams();

//     // Use the custom query hook to fetch shop info, but only if shopInfo exists
//     const { data: shopinfo, isLoading, isError } = useGetShopInfoQuery(shopInfo?._id);
//     const [shopLogout] = useShopLogoutMutation(); // Using the logout mutation

//     useEffect(() => {
//         if (shopInfo?.user?._id) {
//             dispatch(getAllProductsShop(shopInfo.user._id));
//         }
//     }, [dispatch, shopInfo?.user?._id]);
    const logoutHandler = async () => {
        
            const res = await shopLogout(); // Call the logout API
            dispatch(setShopLogout()); // Reset Redux state
            toast.success(res.message || "Logout successful");

            window.location.href = "/"; // Redirect to login


// Call the logout mutation
    };

    const totalReviewsLength =
        products &&
        products.reduce((acc, product) => acc + product.reviews.length, 0);

    const totalRatings = products && products.reduce((acc, product) => acc + product.reviews.reduce((sum, review) => sum + review.rating, 0), 0);

    const averageRating = totalReviewsLength ? (totalRatings / totalReviewsLength).toFixed(2) : 0;

    if (isLoading) return <Loader />;
    if (isError) return <div>Error loading shop information.</div>;

    return (
        <div>
            <div className="w-full py-5">
                <div className="w-full flex item-center justify-center">
                    <img
                        src={`${backend_url}${shopinfo.shop.avatar}`}
                        alt=""
                        className="w-[150px] h-[150px] object-cover rounded-full"
                    />
                </div>
                <h3 className="text-center py-2 text-[20px]">{shopinfo.shop.name}</h3>
                <p className="text-[16px] text-[#000000a6] p-[10px] flex items-center">
                    {shopinfo.shop.description}
                </p>
            </div>
            <div className="p-3">
                <h5 className="font-[600]">Address</h5>
                <h4 className="text-[#000000a6]">{shopinfo.shop.address}</h4>
            </div>
            <div className="p-3">
                <h5 className="font-[600]">Phone Number</h5>
                <h4 className="text-[#000000a6]">{shopinfo.shop.phoneNumber}</h4>
            </div>
            <div className="p-3">
                <h5 className="font-[600]">Total Products</h5>
                <h4 className="text-[#000000a6]">{products && products.length}</h4>
            </div>
            <div className="p-3">
                <h5 className="font-[600]">Shop Ratings</h5>
                <h4 className="text-[#000000b0]">{averageRating}/5</h4>
            </div>
            <div className="p-3">
                <h5 className="font-[600]">Joined On</h5>
                <h4 className="text-[#000000b0]">{shopinfo.shop?.createdAt?.slice(0, 10)}</h4>
            </div>
            {isOwner && (
                <div className="py-3 px-4">
                    <Link to="/settings">
                        <div className={`${styles.button} !w-full !h-[42px] !rounded-[5px]`}>
                            <span className="text-white">Edit Shop</span>
                        </div>
                    </Link>

                    <div className={`${styles.button} !w-full !h-[42px] !rounded-[5px]`}
                        onClick={logoutHandler}
                    >
                        <span className="text-white">Log Out</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ShopInfo;



