
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define the base URL for the user API
const USERS_URL = 'https://api.viceindustries.in/api/v2';

// Create the user API slice
export const orderApi = createApi({
  reducerPath: 'orderApi',
  baseQuery: fetchBaseQuery({
    baseUrl: USERS_URL,
    credentials: 'include', 
  }),
  endpoints: (builder) => ({

    getAllOrders: builder.query({
      query: (userId) => `order/get-all-orders/${userId}`,
    }),

    updateOrderRefund: builder.mutation({
      query: ({ id, status }) => ({
        url: `/order/order-refund/${id}`,
        method: 'PUT',
        body: { status },
        credentials: 'include',
      }),
    }),
    createReview: builder.mutation({
      query: ({ user, rating, comment, productId, orderId, type }) => ({
        url: type === 'product' 
          ? '/product/create-new-review' 
          : '/event/create-new-review-event',
        method: 'PUT',
        body: { user, rating, comment, productId, orderId },
        credentials: 'include',
      }),
    }),

    updateOrderStatus: builder.mutation({
      query: ({ orderId, status }) => ({
        url: `order/update-order-status/${orderId}`,
        method: 'PUT',
        body: { status },
      }),
    }),
    orderRefundSuccess: builder.mutation({
      query: ({ orderId }) => ({
        url: `order/order-refund-success/${orderId}`,
        method: 'PUT',
      }),
    }),
  
    getAllSellerOrders: builder.query({
      query: (shopId) => `order/get-seller-all-orders/${shopId}`, // Adjust the endpoint as needed
    }),
    getAllAdminOrders: builder.query({
      query: () =>"/order/admin-all-orders",
    }),
  }),
});

export const {useCreateReviewMutation,useUpdateOrderRefundMutation,useGetAllAdminOrdersQuery,useOrderRefundSuccessMutation,useUpdateOrderStatusMutation,useGetAllOrdersQuery,useGetAllSellerOrdersQuery} = orderApi;


