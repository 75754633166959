
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define the base URL for the user API
const USERS_URL = 'https://api.viceindustries.in/api/v2';

// Create the user API slice
export const productApi = createApi({
  reducerPath: 'productApi',
  baseQuery: fetchBaseQuery({
    baseUrl: USERS_URL,
    credentials: 'include', 
  }),
  endpoints: (builder) => ({
    createNewConversation: builder.mutation({
      query: (data) => ({
        url: '/conversation/create-new-conversation', 
        method: 'post',
        body: data,
      }),
    }),
    getAllConversationSeller: builder.query({
      query: (sellerId) => `/conversation/get-all-conversation-seller/${sellerId}`,
    }),
    createProduct: builder.mutation({
      query: (data) => ({
        url: '/product/create-product', 
        method: 'post',
        body: data,
      }),
    }),
    deleteProductShop: builder.mutation({
      query: (id) => ({
        url: `/product/delete-shop-product/${id}`, 
        method: 'post',
      }),
    }),
    getAllProductsShop: builder.query({
      query: (id) => `/product/get-all-products-shop/${id}`, // Adjust the endpoint as needed
    }),

    getAllProducts: builder.query({
      query: () => "/product/get-all-products", // Adjust the endpoint as needed
    }),


    getMessages: builder.query({
      query: (currentChatId) => `message/get-all-messages/${currentChatId}`,
    }),




  }),
});

export const {useGetAllProductsShopQuery,useDeleteProductShopMutation,useGetAllProductsQuery,useCreateProductMutation,useGetMessagesQuery, useCreateNewConversationMutation,useGetAllConversationSellerQuery} = productApi;


