
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { backend_url } from "../../server";
import { AiOutlineCamera } from "react-icons/ai";
import styles from "../../styles/styles";
import { loadSeller } from "../../redux/actions/user";
import { toast } from "react-toastify";
import { useUpdateShopAvatarMutation, useUpdateSellerInfoMutation } from "../../redux/shopApiSlice";

const ShopSettings = () => {
    const { shopInfo } = useSelector((state) => state.shopauth);
    const [avatar, setAvatar] = useState();
    const [name, setName] = useState(shopInfo.user?.name || "");
    const [description, setDescription] = useState(shopInfo.user?.description || "");
    const [address, setAddress] = useState(shopInfo.user?.address || "");
    const [phoneNumber, setPhoneNumber] = useState(shopInfo.user?.phoneNumber || "");
    const [zipCode, setZipcode] = useState(shopInfo.user?.zipCode || "");

    const dispatch = useDispatch();

    const [updateShopAvatar] = useUpdateShopAvatarMutation();
    const [updateSellerInfo] = useUpdateSellerInfoMutation();
console.log(shopInfo.user);

    const handleImage = async (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        setAvatar(file);

        const formData = new FormData();
        formData.append("image", file);

        try {
            await updateShopAvatar(formData).unwrap();
            dispatch(loadSeller());
            toast.success("Avatar updated successfully!");
        } catch (error) {
            toast.error(error.data.message);
        }
    };

    const updateHandler = async (e) => {
        e.preventDefault();

        try {
            await updateSellerInfo({
                name,
                address,
                zipCode,
                phoneNumber,
                description,
            }).unwrap();
            toast.success("Shop info updated successfully!");
            dispatch(loadSeller());
        } catch (error) {
            toast.error(error.data.message);
        }
    };

    return (
        <div className="w-full min-h-screen flex flex-col items-center">
            <div className="flex w-full 800px:w-[80%] flex-col justify-center my-5">
                <div className="w-full flex items-center justify-center">
                    <div className="relative">
                        <img
                            src={avatar ? URL.createObjectURL(avatar) : `${backend_url}/${shopInfo.user.avatar}`}
                            alt=""
                            className="w-[200px] h-[200px] rounded-full cursor-pointer"
                        />
                        <div className="w-[30px] h-[30px] bg-[#E3E9EE] rounded-full flex items-center justify-center cursor-pointer absolute bottom-[10px] right-[15px]">
                            <input
                                type="file"
                                id="image"
                                className="hidden"
                                onChange={handleImage}
                            />
                            <label htmlFor="image">
                                <AiOutlineCamera />
                            </label>
                        </div>
                    </div>
                </div>

                {/* Shop info */}
                <form
                    aria-required={true}
                    className="flex flex-col items-center"
                    onSubmit={updateHandler}
                >
                    <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
                        <div className="w-full pl-[3%]">
                            <label className="block pb-2">Shop Name</label>
                        </div>
                        <input
                            type="text"
                            placeholder={shopInfo.user.name}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                            required
                        />
                    </div>
                    <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
                        <div className="w-full pl-[3%]">
                            <label className="block pb-2">Shop Description</label>
                        </div>
                        <input
                            type="text"
                            placeholder={shopInfo.user?.description || "Enter your shop description"}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                        />
                    </div>
                    <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
                        <div className="w-full pl-[3%]">
                            <label className="block pb-2">Shop Address</label>
                        </div>
                        <input
                            type="text"
                            placeholder={shopInfo.user?.address}
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                            required
                        />
                    </div>

                    <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
                        <div className="w-full pl-[3%]">
                            <label className="block pb-2">Shop Phone Number</label>
                        </div>
                        <input
                            type="tel"
                            placeholder={shopInfo.user?.phoneNumber}
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                            required
                        />
                    </div>

                    <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
                        <div className="w-full pl-[3%]">
                            <label className="block pb-2">Shop Zip Code</label>
                        </div>
                        <input
                            type="text"
                            placeholder={shopInfo.user?.zipCode}
                            value={zipCode}
                            onChange={(e) => setZipcode(e.target.value)}
                            className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                            required
                        />
                    </div>

                    <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
                        <input
                            type="submit"
                            value="Update Shop"
                            className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ShopSettings;
